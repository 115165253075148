<template>
  <vue-good-table
    :columns="columns"
    :rows="rows"
    :search-options="{
      enabled: true,
      externalQuery: searchTerm
    }"
    :select-options="{
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }"
    :pagination-options="{ enabled: true, perPage: pageLength }"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <!-- Column: Name -->
      <span v-if="props.column.field === 'teste'">
        <span>{{ props.row.teste }}</span>
      </span>
      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0 mt-1">
          <span class="text-nowrap ">
            Mostrando 1 até
          </span>
          <b-form-select
            v-model="pageLength"
            :options="['5', '10', '30']"
            class="mx-1"
            @input="value => props.perPageChanged({ currentPerPage: value })"
          />
          <span class="text-nowrap"> de {{ props.total }} registros </span>
        </div>
        <div>
          <b-pagination
            :value="1"
            :total-rows="props.total"
            :per-page="pageLength"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @input="value => props.pageChanged({ currentPage: value })"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </template>

    <div slot="emptystate">
      Nenhum registro encontrado!
    </div>
  </vue-good-table>
</template>

<script>
import { BPagination, BFormSelect } from "bootstrap-vue"
import { VueGoodTable } from "vue-good-table"
import useJwt from "@/auth/jwt/useJwt"
import Ripple from "vue-ripple-directive"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormSelect
  },
  directives: { Ripple },

  data() {
    return {
      msg: "Jogo Memoria",
      autoKey: "consultar-0",
      pageLength: 10,
      dir: false,
      usuarioId: 0,
      rows: [],
      searchTerm: "",
      idDoPerfil: this.$store.state.user.idDoPerfil,
      // Dados para enviar
      columns: [
        {
          label: "Jogo",
          field: "teste"
        },
        {
          label: "Data",
          field: "data",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss",
          dateOutputFormat: "dd/MM/yyyy"
        },
        {
          label: "Qtd Acertos",
          field: "qtdAcertos"
        },
        {
          label: "Qtd Erros",
          field: "qtdErros"
        },

        {
          label: "Pontos",
          field: "pontos"
        },
        {
          label: "Finalização",
          field: "finalizacao"
        }
      ]
    }
  },
  mounted() {
    this.usuarioId = parseInt(this.$route.params.id)
    this.getlistarDetalheMediaMemoria(this.usuarioId)
  },
  methods: {
    async getlistarDetalheMediaMemoria(usuarioId) {
      const resp = await useJwt
        .listarDetalheMediaMemoria(usuarioId)
        .then(response => response.data)
        .catch(error => {
          // error
          this.MensagemToastgetlistarDetalheMediaMemoria(
            "warning",
            "top-center",
            error
          )
          return false
        })
      if (resp.sucesso) {
        this.rows = resp.dados
      }
    },
    MensagemToastgetlistarDetalheMediaMemoria(variant, position, mensagem) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Não foi possível realizar a busca das informações.",
            icon: "XCircleIcon",
            variant,
            text: "Erro identificado: " + mensagem
          }
        },
        {
          position
        }
      )
    }
  }
}
</script>

<style scoped>
.icone {
  color: inherit;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
