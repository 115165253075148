<template>
  <vue-good-table
    :columns="columns1"
    :rows="rows1"
    :search-options="{
      enabled: true,
      externalQuery: searchTerm
    }"
    :select-options="{
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }"
    :pagination-options="{
      enabled: true,
      perPage: pageLength
    }"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <!-- Column: Name -->
      <span v-if="props.column.field === 'teste'">
        <span>{{ props.row.teste }}</span>
      </span>
      <!-- Column: Desvio -->
      <span v-else-if="props.column.field === 'desvioTela'">
        <b-badge :variant="statusVariant(props.row.tipoClassificacao)">
          {{ props.row.desvioTela }}
        </b-badge>
      </span>
      <!-- Column: Action -->
      <span v-else-if="props.column.field === 'action'">
        <span>
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <!-- <b-dropdown-item
                        :to="{
                          name: 'visualizar-trabalho',
                          params: { idDoLocal: props.row.id }
                        }"
                      >
                        <feather-icon icon="FileTextIcon" />
                        <span class="align-middle ml-50">Detalhes</span>
                      </b-dropdown-item> -->

            <b-dropdown-item
              :to="{
                name: 'editar-trabalho',
                params: { idDoLocal: props.row.id }
              }"
            >
              <feather-icon
                icon="Edit2Icon"
                class="mr-50"
              />
            </b-dropdown-item>
          </b-dropdown>
        </span>
      </span>

      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>

    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0 mt-1">
          <span class="text-nowrap ">
            Mostrando 1 até
          </span>
          <b-form-select
            v-model="pageLength"
            :options="['5', '10', '30']"
            class="mx-1"
            @input="value => props.perPageChanged({ currentPerPage: value })"
          />
          <span class="text-nowrap"> de {{ props.total }} registros </span>
        </div>
        <div>
          <b-pagination
            :value="1"
            :total-rows="props.total"
            :per-page="pageLength"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @input="value => props.pageChanged({ currentPage: value })"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </template>
    <div slot="emptystate">
      Nenhum registro encontrado!
    </div>
  </vue-good-table>
</template>

<script>
import { BFormSelect, BPagination, BBadge } from "bootstrap-vue"
import { VueGoodTable } from "vue-good-table"
import useJwt from "@/auth/jwt/useJwt"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BFormSelect,
    VueGoodTable,
    // eslint-disable-next-line vue/no-unused-components
    BPagination,
    BBadge
  },
  data() {
    return {
      autoKey: "consultar-0",
      pageLength: 10,
      dir: false,
      usuarioId: 0,
      rows1: [],
      searchTerm: "",
      locale: "pt-BR",
      idDoPerfil: this.$store.state.user.idDoPerfil,
      // Dados para enviar
      paginaAtual: 1,

      columns1: [
        {
          label: "Jogo",
          field: "teste"
        },
        {
          label: "Data",
          field: "data",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss",
          dateOutputFormat: "dd/MM/yyyy"
        },
        {
          label: "Qtd Acertos",
          field: "qtdAcerto"
        },
        {
          label: "Qtd Erros",
          field: "qtdErro"
        },
        {
          label: "Qtd Omissões",
          field: "qtdOmissao"
        },
        {
          label: "Pontos",
          field: "pontos"
        },
        {
          label: "Desvio",
          field: "desvioTela"
        },
        {
          label: "Finalização",
          field: "finalizacao"
        },
        {
          label: "Classificação",
          field: "classificacao"
        }
      ]
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1: "light-success",
        2: "light-danger"
        /* eslint-enable key-spacing */
      }
      return desvioTela => statusColor[desvioTela]
    }
  },
  mounted() {
    this.usuarioId = parseInt(this.$route.params.id)
    this.getlistarDetalheHistoricoConcentracao(this.usuarioId)
  },
  methods: {
    async getlistarDetalheHistoricoConcentracao(usuarioId) {
      const resp = await useJwt
        .listarDetalheHistoricoConcentracao(usuarioId)
        .then(response => response.data)
        .catch(error => {
          // error
          this.MensagemToastgetlistarDetalheHistoricoConcentracao(
            "warning",
            "top-center",
            error
          )
          return false
        })
      if (resp.sucesso) {
        this.rows1 = resp.dados
      }
    },
    MensagemToastgetlistarDetalheHistoricoConcentracao(
      variant,
      position,
      mensagem
    ) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Não foi possível realizar a busca das informações.",
            icon: "XCircleIcon",
            variant,
            text: "Erro identificado: " + mensagem
          }
        },
        {
          position
        }
      )
    }
  }
}
</script>

<style scoped>
.icone {
  color: inherit;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
