var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
    enabled: true,
    externalQuery: _vm.searchTerm
  },"select-options":{
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
  },"pagination-options":{ enabled: true, perPage: _vm.pageLength }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'teste')?_c('span',[_c('span',[_vm._v(_vm._s(props.row.teste))])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap "},[_vm._v(" Mostrando 1 até ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['5', '10', '30']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" de "+_vm._s(props.total)+" registros ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" Nenhum registro encontrado! ")])])}
var staticRenderFns = []

export { render, staticRenderFns }