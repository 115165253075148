<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card-actions
          title="Informações gerais por tipo de jogo"
          action-collapse
        >
          <div>
            <b-row>
              <!-- Permite master escolher a empresa -->
              <b-col md="3">
                <b-form-group label="Empresa:">
                  <b-form-input
                    v-model="form.empresa"
                    placeholder="teste"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Local de Trabalho:">
                  <b-form-input
                    v-model="form.localDeTrabalho"
                    placeholder="teste"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Gestor:">
                  <b-form-input
                    v-model="form.gestor"
                    placeholder="teste"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Usuario:">
                  <b-form-input
                    v-model="form.nomeDoUsuario"
                    placeholder="teste"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-group label="Cargo:">
                  <b-form-input
                    v-model="form.cargo"
                    placeholder="teste"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Idade:">
                  <b-form-input
                    v-model="form.idade"
                    placeholder="teste"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Média:">
                  <b-form-input
                    v-model="form.media"
                    placeholder="teste"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Desvio Padrão:">
                  <b-form-input
                    v-model="form.desvio"
                    placeholder="teste"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-group label="Cuidado:">
                  <b-form-input
                    v-model="form.cuidado"
                    placeholder="teste"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Inapto (menor que):">
                  <b-form-input
                    v-model="form.inapto"
                    placeholder="teste"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Jogo:">
                  <b-form-input
                    v-model="nomeJogo"
                    placeholder="teste"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <!-- <b-col md="3">
                <b-form-group label="Jogo:">
                  <v-select
                    v-model="selected"
                    :options="options"
                    label="text"
                    placeholder="Concentração"
                  >
                    <span slot="no-options">
                      Opção não encontrada
                    </span>
                  </v-select>
                </b-form-group>
              </b-col> -->
            </b-row>
          </div>
        </b-card-actions>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card-actions
          title="Jogos realizados para calcular a média"
          action-collapse
        >
          <div v-if="tipoJogo === 1">
            <MediaConcentracao />
          </div>
          <div v-else-if="tipoJogo === 2">
            <MediaMemoria />
          </div>
        </b-card-actions>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <b-card-actions
          title="Jogos realizados para avaliação de classificação"
          action-collapse
        >
          <div v-if="tipoJogo === 1">
            <HistoricoConcentracao />
          </div>
          <div v-else-if="tipoJogo === 2">
            <HistoricoMemoria />
          </div>
        </b-card-actions>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  VBPopover,
  VBTooltip,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton
} from "bootstrap-vue"
import vSelect from "vue-select"
import BCardActions from "@core/components/b-card-actions/BCardActions.vue"
import store from "@/store/index"
import MediaMemoria from "@/views/global/MediaMemoria.vue"
import MediaConcentracao from "@/views/global/MediaConcentracao.vue"
import HistoricoConcentracao from "@/views/global/HistoricoConcentracao.vue"
import HistoricoMemoria from "@/views/global/HistoricoMemoria.vue"
import useJwt from "@/auth/jwt/useJwt"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  components: {
    // vSelect,
    // BCard,
    BCardActions,
    MediaMemoria,
    MediaConcentracao,
    HistoricoConcentracao,
    HistoricoMemoria,
    BRow,
    BCol,

    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,

    // eslint-disable-next-line vue/no-unused-components
    BButton
    // BFormSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-popover": VBPopover
  },
  data() {
    return {
      selected: { value: 1, text: "Atenção" },
      idDoPerfil: this.$store.state.user.idDoPerfil,
      // Dados para enviar
      paginaAtual: 1,
      usuarioId: 0,
      nomeJogo: "",
      tipoJogo: 0,

      form: {
        empresa: "",
        localDeTrabalho: "",
        gestor: "",
        nomeDoUsuario: "",
        cargo: "",
        idade: 0,
        media: 0,
        desvio: 0,
        cuidado: 0,
        inapto: 0
      },

      options: [
        { value: 1, text: "Atenção" },
        { value: 2, text: "Memória" }
      ]
    }
  },
  computed: {
    // pageRefresh() {
    //   return this.$store.state.user.idDoPerfil
    // }
  },

  mounted() {
    this.usuarioId = parseInt(this.$route.params.id)
    this.tipoJogo = parseInt(this.$route.params.tipoJogo)
    if (this.tipoJogo == 1) {
      this.nomeJogo = "Atenção"
    } else if (this.tipoJogo == 2) {
      this.nomeJogo = "Memória"
    }

    // this.$refs["loadingCard"].showLoading = true
    this.getConsultaDetalheUsuario(this.usuarioId, this.tipoJogo)
    this.selected.value = 1
  },

  methods: {
    async getConsultaDetalheUsuario(usuarioId, tipoJogo) {
      const resp = await useJwt
        .consultarResultadoDetalhado(usuarioId, tipoJogo)
        .then(response => response.data)
        .catch(error => {
          // error
          this.MensagemToastGetConsultaDetalhadaUsuario(
            "warning",
            "top-center",
            error
          )
          return false
        })
      if (resp.sucesso) {
        this.form = resp.dados
      }
    },
    MensagemToastGetConsultaDetalhadaUsuario(variant, position, mensagem) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Não foi possível realizar a busca das informações.",
            icon: "XCircleIcon",
            variant,
            text: "Erro identificado: " + mensagem
          }
        },
        {
          position
        }
      )
    }
    // onSelected() {
    //   console.log("TEstandooo: ", this.selected.value)
    // }
  }
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
